<template>
  <service-template
    :samples="samples"
    :on-run="handleResponse"
    :header="$t('sld.header')"
    :subheader="$t('sld.subheader')"
    :icon="require('@/assets/images/icons/services/ASA/SLD/header.svg')"
  >
    <template v-slot:result>
      <result-percentages
        v-if="result.length"
        :data="result"
      />
    </template>
  </service-template>
</template>

<script>
import ResultPercentages from '@/views/pages/AIServices/components/ResultPercentages/Index.vue';
import ServiceTemplate from '../ServiceTemplate.vue';

export default {
  components: { ServiceTemplate, ResultPercentages },
  data: () => ({ samples: [], result: [] }),
  async mounted() {
    const result = await this.$api.asa.sld.getSampleAudios();

    this.samples = result.samples.map((sample) => ({ ...sample, path: `${this.$consts.config.CDN.SERVER}${sample.path}` }));
  },
  methods: {
    async handleResponse(formData) {
      const { data } = await this.$api.asa.sld.postSpokenLanguageDetection(formData);
      const result = data.channels[0].languages;
      this.result = Object.keys(result).map((language) => ({ topic: this.$t(`languages['${language}']`), score: result[language] }));
    },
  },
};
</script>
