<template>
  <div class="result-chart flex flex-1 flex-col">
    <div
      v-for="item in data.slice().sort((x, y) => y.score - x.score)"
      :key="item.topic"
      class="result-item"
    >
      <div class="description">
        <span class="name">{{ item.topic }}</span>
        <span class="percentage">{{ `${item.score}%` }}</span>
      </div>
      <el-progress
        :percentage="item.score"
        :show-text="false"
        :stroke-width="12"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
$progress-bg: #dbdfeb;

.result-chart {
  .result-item {
    margin-top: 0.12rem;
    font-size: 0.14rem;
    line-height: 0.17rem;

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.07rem;
    }

    .name {
      font-weight: 600;
    }

    .percentage {
      font-weight: 400;
    }

    /deep/ .el-progress-bar__outer {
      background: $progress-bg;
    }
  }
}
</style>
